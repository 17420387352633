import React, { useState } from 'react'
import CompletedLoansItem from './CompletedLoansItem'
import TableListComponent from '../../generalComponents/TableListComponent'

const CompletedLoansList = ({ completedLoans }) => {
    const completedLoansHeader = ['DATE CREATED', 'USERNAME', 'AMOUNT RECIEVED', 'AMOUNT PAID', 'DATE COMPLETED', 'NO OF DAYS TO COMPLETE', 'ACTIONS']
    return (
    <div>
      <div className='text-xs user-balance-table'>
        <TableListComponent headerList={completedLoansHeader} itemComponent={CompletedLoansItem} dataList={completedLoans} fontSize={10} />
      </div>
    </div>
  )
}

export default CompletedLoansList
