import React from 'react'

const LoansActivityItem = ({ itemData }) => {
    // const completedLoansHeader = ['DATE CREATED', 'USERNAME', 'TOTAL AMOUNT', 'DATE COMPLETED', 'NO OF DAYS TO COMPLETE', 'ACTIONS']
  return (
    <tr className='loans-analysis-btn'>
        <td className="whitespace-nowrap px-px py-4 border-b">{itemData?.activity}</td>
        <td className="whitespace-nowrap px-px py-4 border-b">{itemData?.amount}</td>
        <td className="whitespace-nowrap px-px py-4 border-b">{itemData?.description}</td>
        <td className="whitespace-nowrap px-px py-4 border-b">{itemData?.date}</td>
        <td className="whitespace-nowrap px-px py-4 border-b text-center">...</td>
    </tr>
  )
}

export default LoansActivityItem
