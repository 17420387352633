import React, { useState } from 'react'
import RequestedLoansItem from './RequestedLoansItem'
import TableListComponent from '../../generalComponents/TableListComponent'

const RequestedLoansList = ({ requestedLoans }) => {
  const headerList = ['DATE', 'USERNAME', 'CREDI SCORE', 'LOAN REQUESTED', 'STATUS', 'ACTIONS']
  return (
    <div>
      <div className='text-xs user-balance-table'>
        <TableListComponent headerList={headerList} itemComponent={RequestedLoansItem} dataList={requestedLoans} fontSize={10} />
      </div>
    </div>
  )
}

export default RequestedLoansList
