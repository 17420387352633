import React from 'react'

const LoansTotalCard = ({bgColor, title, amount}) => {
  return (
    <div className={`bg-[${bgColor}] p-3 rounded-2xl`}>
        <p className=' text-xs text-[#FFFFFF] mb-4' style={{fontSize: 10}}>{title}</p>
        <h2 className='text-[#FFFFFF] text-xl mb-3'>{amount}</h2>
    </div>
  )
}

export default LoansTotalCard
