import React, { useState } from 'react'
import TableListComponent from '../../generalComponents/TableListComponent'
import OverdueLoansItem from './OverdueLoansItem'

const OverdueLoansList = ({ overdueLoans }) => {
    const overdueLoansHeader = ['DATE CREATED', 'USERNAME', 'OVERDUE DATE', 'TOTAL LOAN', 'BALANCE', 'STATUS', 'ACTIONS']; 

    return (
    <div>
      <div className='text-xs user-balance-table'>
        <TableListComponent headerList={overdueLoansHeader} itemComponent={OverdueLoansItem} dataList={overdueLoans} fontSize={10} />
      </div>
    </div>
  )
}

export default OverdueLoansList
