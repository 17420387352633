import React from 'react'

export const Shimmer = () => {
  return (
    <div>
        <div className="shimmer-wrapper">
            <div className="shimmer">
                
            </div>
        </div>
    </div>
  )
}
