import React from 'react'
import blueArrow from '../../asserts/Icons/blue-arrow.png'
import whiteArrow from '../../asserts/Icons/white-arrow.png'

const TotalCard = ({ title, year, total, bgColor, textColor, arrow }) => {
  const style = {
    backgroundColor: bgColor,
    color: textColor
  }
    return (
    <div className='finance-total-card shadow-xl' style={style}>
      <div className='mb-5'>
        <p className='text-xl font-bold'>{title}{year}</p>
        <h2 className='text-xl font-bold'>NGN {total}</h2>
      </div>
      <img className='arrow' src={arrow === 'blue' ? blueArrow : whiteArrow} alt="" />
    </div>
  )
}

export default TotalCard
