import React, { useState } from 'react'
import OutstandingLoansItem from './OutstandingLoansItem'
import TableListComponent from '../../generalComponents/TableListComponent'

const OutstandingLoansList = ({ outstandingLoans }) => {
  const outstandingLoansHeader = ['DATE CREATED', 'USERNAME', 'MONTHLY PAYMENT', 'TOTAL LOAN', 'BALANCE', 'STATUS', 'ACTIONS']
  return (
    <div>
      <div className='text-xs user-balance-table'>
        <TableListComponent headerList={outstandingLoansHeader} itemComponent={OutstandingLoansItem} dataList={outstandingLoans} fontSize={10} />
      </div>
    </div>
  )
}

export default OutstandingLoansList
