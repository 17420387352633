import React from 'react'

const RegistrationsItem = ({ itemData }) => {
  return (
    <tr>
        <td className="whitespace-nowrap px-px py-4 border-b">{itemData.date}</td>
        <td className="whitespace-nowrap px-px py-4 border-b">{itemData?.amount}</td>
        <td className="whitespace-nowrap px-px py-4 border-b">{itemData?.username}</td>
        <td className="whitespace-nowrap px-px py-4 border-b text-center">
            <button className={itemData?.userType === 'Tenant' ? `tenant-btn` : itemData?.userType === 'Landlord' ? `landlord-btn` : itemData?.userType === 'Agent' ? `agent-btn` : itemData?.userType === 'Seeker' ? `seeker-btn` : '' }>{itemData?.userType}</button>
        </td>
        <td className="whitespace-nowrap px-px py-4 border-b">{itemData?.city} - {itemData.state}</td>
        <td className="whitespace-nowrap px-px py-4 border-b text-center">...</td>
    </tr>
  )
}

export default RegistrationsItem
