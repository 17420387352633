import React, { useState, useEffect } from 'react'
import OverallRevenue from '../revenueComponents/OverallRevenue'
import searchIcon from '../../../asserts/Icons/search-icon.png'
import filterIcon from '../../../asserts/Icons/filter.png'
import plusIcon from '../../../asserts/Icons/plus.png'
import PropertiesList from '../revenueComponents/PropertiesList'
import RegistrationsList from '../revenueComponents/RegistrationsList'
import RentCommissionList from '../revenueComponents/RentCommissionList'
import InterestList from '../revenueComponents/InterestList'
import handleTabs from '../../generalComponents/HandleTabs'

const FinanceRevenueTab = () => {
  const [searchItem, setSearchItem] = useState('');
  const [properties, setProperties] = useState(true);
  const [registration, setRegistration] = useState(false);
  const [rentCommission, setRentCommision] = useState(false);
  const [interests, setInterest] = useState(false);

  const propertiesList = [
    {
        date: 'Thu 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Samuel Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 20 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Esther Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Fri 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N600,000,000.00',
        agent: 'Samson Sunday',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Mon 14 Oct 2022 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34671',
        value: 'N400,000,000.00',
        agent: 'Blessing Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Samuel Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Samuel Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 20 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Esther Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Fri 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N600,000,000.00',
        agent: 'Samson Sunday',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Mon 14 Oct 2022 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34671',
        value: 'N400,000,000.00',
        agent: 'Blessing Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Samuel Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Samuel Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 20 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Esther Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Fri 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N600,000,000.00',
        agent: 'Samson Sunday',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Mon 14 Oct 2022 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34671',
        value: 'N400,000,000.00',
        agent: 'Blessing Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Samuel Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Samuel Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 20 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Esther Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Fri 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N600,000,000.00',
        agent: 'Samson Sunday',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Mon 14 Oct 2022 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34671',
        value: 'N400,000,000.00',
        agent: 'Blessing Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Samuel Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Samuel Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 20 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Esther Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Fri 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N600,000,000.00',
        agent: 'Samson Sunday',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Mon 14 Oct 2022 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34671',
        value: 'N400,000,000.00',
        agent: 'Blessing Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
    {
        date: 'Thu 14 Sept 2023 09:56 am GMT+1',
        propertyId: 'PROP-CRS-34571',
        value: 'N400,000,000.00',
        agent: 'Samuel Balogun',
        state: 'Cross River',
        city: 'Calabar'
    },
];

  const registrationsList = [
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Samuel Balogun',
      userType: 'Landlord',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '5,500',
      username: 'Samuel Balogun',
      userType: 'Agent',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Jogn Balogun',
      userType: 'Tenant',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Samuel Samson',
      userType: 'Landlord',
      city: 'Odupkani',
      state: 'Cross River'
    },
    {
      date: 'Thu 20 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Samuel Balogun',
      userType: 'Agent',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Fri 14 Sept 2023 09:56 am GMT+1',
      amount: '15,000',
      username: 'Samuel Balogun',
      userType: 'Tenant',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Samuel Balogun',
      userType: 'Landlord',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '5,500',
      username: 'Samuel Balogun',
      userType: 'Agent',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Jogn Balogun',
      userType: 'Tenant',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Samuel Samson',
      userType: 'Landlord',
      city: 'Odupkani',
      state: 'Cross River'
    },
    {
      date: 'Thu 20 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Samuel Balogun',
      userType: 'Agent',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Fri 14 Sept 2023 09:56 am GMT+1',
      amount: '15,000',
      username: 'Samuel Balogun',
      userType: 'Tenant',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Samuel Balogun',
      userType: 'Landlord',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '5,500',
      username: 'Samuel Balogun',
      userType: 'Agent',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Jogn Balogun',
      userType: 'Tenant',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Samuel Samson',
      userType: 'Landlord',
      city: 'Odupkani',
      state: 'Cross River'
    },
    {
      date: 'Thu 20 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Samuel Balogun',
      userType: 'Agent',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Fri 14 Sept 2023 09:56 am GMT+1',
      amount: '15,000',
      username: 'Samuel Balogun',
      userType: 'Tenant',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Samuel Balogun',
      userType: 'Landlord',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '5,500',
      username: 'Samuel Balogun',
      userType: 'Agent',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Jogn Balogun',
      userType: 'Tenant',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Samuel Samson',
      userType: 'Landlord',
      city: 'Odupkani',
      state: 'Cross River'
    },
    {
      date: 'Thu 20 Sept 2023 09:56 am GMT+1',
      amount: '11,000',
      username: 'Samuel Balogun',
      userType: 'Agent',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Fri 14 Sept 2023 09:56 am GMT+1',
      amount: '15,000',
      username: 'Samuel Balogun',
      userType: 'Tenant',
      city: 'Calabar',
      state: 'Cross River'
    },
  ];

  const rentCommissionList = [
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Samuel Balogun',
      rentAmount: 'N3,500,000',
      commission: 'N315,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Blessing Balogun',
      rentAmount: 'N500,000',
      commission: 'N45,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Tue 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Princess Paul',
      rentAmount: 'N1,500,000',
      commission: 'N135,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Wed 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Grace Nelson',
      rentAmount: 'N250,000',
      commission: 'N22,500',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Sat 20 Sept 2023 09:56 am GMT+1',
      tenant: 'Peter Anderson',
      rentAmount: 'N2,200,000',
      commission: 'N198,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Mon 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Collins Nelson',
      rentAmount: 'N3,500,000',
      commission: 'N315,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Samuel Balogun',
      rentAmount: 'N3,500,000',
      commission: 'N315,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Blessing Balogun',
      rentAmount: 'N500,000',
      commission: 'N45,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Tue 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Princess Paul',
      rentAmount: 'N1,500,000',
      commission: 'N135,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Wed 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Grace Nelson',
      rentAmount: 'N250,000',
      commission: 'N22,500',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Sat 20 Sept 2023 09:56 am GMT+1',
      tenant: 'Peter Anderson',
      rentAmount: 'N2,200,000',
      commission: 'N198,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Mon 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Collins Nelson',
      rentAmount: 'N3,500,000',
      commission: 'N315,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Samuel Balogun',
      rentAmount: 'N3,500,000',
      commission: 'N315,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Blessing Balogun',
      rentAmount: 'N500,000',
      commission: 'N45,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Tue 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Princess Paul',
      rentAmount: 'N1,500,000',
      commission: 'N135,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Wed 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Grace Nelson',
      rentAmount: 'N250,000',
      commission: 'N22,500',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Sat 20 Sept 2023 09:56 am GMT+1',
      tenant: 'Peter Anderson',
      rentAmount: 'N2,200,000',
      commission: 'N198,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Mon 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Collins Nelson',
      rentAmount: 'N3,500,000',
      commission: 'N315,000',
      city: 'Calabar',
      state: 'Cross River'
    },
  ];

  const interestList = [
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Samuel Balogun',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N3,500,000',
      commission: 'N315,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Blessing Balogun',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N500,000',
      commission: 'N45,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Tue 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Princess Paul',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N1,500,000',
      commission: 'N135,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Wed 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Grace Nelson',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N250,000',
      commission: 'N22,500',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Sat 20 Sept 2023 09:56 am GMT+1',
      tenant: 'Peter Anderson',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N2,200,000',
      commission: 'N198,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Mon 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Collins Nelson',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N3,500,000',
      commission: 'N315,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Samuel Balogun',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N3,500,000',
      commission: 'N315,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Blessing Balogun',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N500,000',
      commission: 'N45,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Tue 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Princess Paul',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N1,500,000',
      commission: 'N135,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Wed 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Grace Nelson',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N250,000',
      commission: 'N22,500',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Sat 20 Sept 2023 09:56 am GMT+1',
      tenant: 'Peter Anderson',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N2,200,000',
      commission: 'N198,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Mon 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Collins Nelson',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N3,500,000',
      commission: 'N315,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Samuel Balogun',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N3,500,000',
      commission: 'N315,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Thu 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Blessing Balogun',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N500,000',
      commission: 'N45,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Tue 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Princess Paul',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N1,500,000',
      commission: 'N135,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Wed 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Grace Nelson',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N250,000',
      commission: 'N22,500',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Sat 20 Sept 2023 09:56 am GMT+1',
      tenant: 'Peter Anderson',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N2,200,000',
      commission: 'N198,000',
      city: 'Calabar',
      state: 'Cross River'
    },
    {
      date: 'Mon 14 Sept 2023 09:56 am GMT+1',
      tenant: 'Collins Nelson',
      loanAndInterest: 'N3,955,000',
      duration: 'N659,167/month',
      rentAmount: 'N3,500,000',
      commission: 'N315,000',
      city: 'Calabar',
      state: 'Cross River'
    },
  ];


  var newDataList;

  const handleFilter =()=> {
    if(properties) {
      newDataList = propertiesList.filter(property => (
        property.date.toLowerCase().includes(searchItem.toLowerCase())
        || property.value.toLowerCase().includes(searchItem.toLowerCase())
        || property.agent.toLowerCase().includes(searchItem.toLowerCase())
        || property.city.toLowerCase().includes(searchItem.toLowerCase())
        || property.state.toLowerCase().includes(searchItem.toLowerCase())
      ))
    } else if(registration) {
      newDataList = registrationsList.filter(record => (
        record.date.toLowerCase().includes(searchItem.toLowerCase())
        || record.amount.toLowerCase().includes(searchItem.toLowerCase())
        || record.username.toLowerCase().includes(searchItem.toLowerCase())
        || record.city.toLowerCase().includes(searchItem.toLowerCase())
        || record.state.toLowerCase().includes(searchItem.toLowerCase())
        || record.userType.toLowerCase().includes(searchItem.toLowerCase())
      ))
    } else if(rentCommission) {
      newDataList = rentCommissionList.filter(record => (
        record.date.toLowerCase().includes(searchItem.toLowerCase())
        || record.tenant.toLowerCase().includes(searchItem.toLowerCase())
        || record.rentAmount.toLowerCase().includes(searchItem.toLowerCase())
        || record.commission.toLowerCase().includes(searchItem.toLowerCase())
        || record.state.toLowerCase().includes(searchItem.toLowerCase())
      ))
    } else if(interests) {
      newDataList = interestList.filter(record => (
        record.date.toLowerCase().includes(searchItem.toLowerCase())
        || record.tenant.toLowerCase().includes(searchItem.toLowerCase())
        || record.rentAmount.toLowerCase().includes(searchItem.toLowerCase())
        || record.loanAndInterest.toLowerCase().includes(searchItem.toLowerCase())
        || record.state.toLowerCase().includes(searchItem.toLowerCase())
        || record.city.toLowerCase().includes(searchItem.toLowerCase())
        || record.duration.toLowerCase().includes(searchItem.toLowerCase())
      ))
    }
  }

  searchItem && handleFilter();

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchItem(e.target.value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className='mb-5'>
        <div className="chart-area_item p-5">
            <OverallRevenue />
        </div>
      </div>
      <div className="chart-area_item">
        <div className='flex justify-between items-center mb-5'>
            <div>
                <h3 className='text-xl font-semibold'>Revenue Analysis</h3>
                <p className='text-xs text-gray-500'>View details of all revenue generated</p>
            </div>
            <div className='inline-flex items-baseline'>
                <div className='renenue-nav mr-3'>
                    <span className={properties ? `highlighted-revenue` : ''} onClick={() => handleTabs(setProperties, [setProperties, setRegistration, setRentCommision, setInterest])}>Properties</span>
                    <span className={registration ? `highlighted-revenue` : ''} onClick={() => handleTabs(setRegistration, [setProperties, setRegistration, setRentCommision, setInterest])}>Registrations</span>
                    <span className={rentCommission ? `highlighted-revenue` : ''} onClick={() => handleTabs(setRentCommision, [setProperties, setRegistration, setRentCommision, setInterest])}>Rent Commision</span>
                    <span className={interests ? `highlighted-revenue` : ''} onClick={() => handleTabs(setInterest, [setProperties, setRegistration, setRentCommision, setInterest])}>Interests</span>
                </div>
                <div className="search-filter">
                    <div className="search-bar mr-3 flex justify-between item-center h-10" style={{width: '200px'}}>
                        <input type="text" value={searchItem} onChange={handleSearch} placeholder='Search' />
                        <div>
                            <img src={searchIcon} alt="" />
                        </div>
                    </div>
                    {/* <div>
                        <img src={plusIcon} className='h-8 cursor-pointer mr-3' alt="" />
                    </div>
                    <div className='filter-area'>
                        <img src={filterIcon} alt="" />
                    </div> */}
              </div>
            </div>
        </div>
        <div>
          {
            searchItem.length ? (
                properties ? <PropertiesList properties={newDataList} />  : registration ? <RegistrationsList registrations={newDataList} /> : rentCommission ? <RentCommissionList rentCommissions={newDataList} /> : interests ? <InterestList interests={newDataList} /> : ''
            ) : (
                properties ? <PropertiesList properties={propertiesList} />  : registration ? <RegistrationsList registrations={registrationsList} /> : rentCommission ? <RentCommissionList rentCommissions={rentCommissionList} /> : interests ? <InterestList interests={interestList} /> : ''
            )
          }
        </div>
      </div>
    </div>
  )
}

export default FinanceRevenueTab
