import React from 'react'

const TransactionItem = ({ itemData }) => {
  return (
    <tr className='finance-user-td' key={itemData?.transactionId}>
        <td className="whitespace-nowrap px-px py-4 border-b" style={{fontSize: 8}}>
            <p>{itemData?.date}</p>
            <p>{itemData?.time}</p>
        </td>
        <td className={`${itemData.category === 'PHCN bill' ? `phcn` : itemData?.category === 'NGN to HBP' ? `hpb` : itemData?.category === 'Airtime' ? `airtime` : itemData?.category === 'Rent' ? 'rent' : '' } whitespace-nowrap px-px py-4 border-b`}>
          {itemData?.category}
        </td>
        <td className="whitespace-nowrap px-px py-4 border-b">{itemData?.transactionId}</td>
        <td className="whitespace-nowrap px-px py-4 border-b">{itemData ?.amount}</td>
        <td className={`${itemData.status === 'Failed' ? `failed` : itemData?.status === 'Completed' ? `completed` : itemData?.status === 'Pending' ? `pending` : '' } whitespace-nowrap px-px py-4 border-b `}>
        {itemData?.status}
        </td>        
        <td className="whitespace-nowrap px-px py-4 border-b pl-5">...</td>
    </tr>
  )
}

export default TransactionItem
