import React from 'react'
import LoansTotalCard from '../LoansTabComponents/LoansTotalCard'
import PiechartSkeleton from '../../skeleton/PiechartSkeleton'
import LoansRequestPerformance from '../LoansTabComponents/LoansRequestPerformance'
import NoAvailableData from '../../generalComponents/NoAvailableData'
import OfflinePerformanceLabel from '../offlineComponents/OfflinePerformanceLabel'
import LoansAnalysisTable from '../LoansTabComponents/LoansAnalysisTable'
import LoansActivityTable from '../LoansTabComponents/LoansActivityTable'

const FinanceLoansTab = (loansPerformance) => {

  return (
    <div className=''>
        <div className='chart-area'>
            <div className="chart-area_item flex justify-center items-center">
                <div className='w-2/4'>
                    {
                        loansPerformance ? (
                            <div className=''>
                            {
                                loansPerformance.pending === 0 && loansPerformance.approved === 0 && loansPerformance.declined === 0 ? (
                                    <NoAvailableData notice='No Chart Data'/>
                                ) : (
                                    <>
                                        <div className='piechart text-center my-5'>
                                            <p className="font-bold mb-3">Loans Request Performance</p>
                                            <LoansRequestPerformance loansPerformance={loansPerformance} />
                                            <div className="piechart-center">
                                                <div className=' font-semibold' style={{fontSize: 12}}>
                                                    <p>Loan Request Performance</p>
                                                    <p>75</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex justify-center'>
                                            <OfflinePerformanceLabel color={'#DA9D62'} title={'Pending'} value={32} />
                                            <OfflinePerformanceLabel color={'#1CAA43'} title={'Approved'} value={29} />
                                            <OfflinePerformanceLabel color={'#E93B3B'} title={'Declined'} value={14} />
                                        </div>
                                    </>
                                )
                            }
                            </div>
                        ) : (
                            <PiechartSkeleton />
                        )
                    }
                </div>
            </div>

            <div className="grid grid-cols-2 gap-2 w-full  chart-area_item">
                <LoansTotalCard bgColor={'#0A1045'} title={'TOTAL REQUESTED LOAN'} amount={'N70.50M'} />
                <LoansTotalCard bgColor={'#1EA06A'} title={'TOTAL LOANS APPROVED'} amount={'N50M'} />
                <LoansTotalCard bgColor={'#1B49FF'} title={'TOTAL lOAN GIVEN'} amount={'N30M'} />
                <LoansTotalCard bgColor={'#0A1045'} title={'TOTAL LOAN REFUNDED'} amount={'N30M'} />
                <LoansTotalCard bgColor={'#1EA06A'} title={'TOTAL LOAN OWED'} amount={'N30M'} />
            </div>
        </div>

        <div className='mb-5'>
            <LoansAnalysisTable />
        </div>

        <div>
            <LoansActivityTable />
        </div>
    </div>
  )
}

export default FinanceLoansTab
